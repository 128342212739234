import React from "react"
import { Mail, Twitter, Instagram } from "react-feather"
import { Link } from "gatsby"

const Footer = () => {
  return (
    <footer className="container">
      <div className="texts">
        <span className="text">© 2022 Focal LLC</span>
        <Link to="/terms" className="text">
          Terms
        </Link>
        <Link to="/privacy" className="text">
          Privacy
        </Link>
      </div>
      <div className="icons">
        <a href="mailto:info@getfocal.app" className="icon">
          <Mail />
        </a>
        <a href="https://twitter.com/focalapp" className="icon">
          <Twitter />
        </a>
        <a href="https://www.instagram.com/focal.app/" className="icon">
          <Instagram />
        </a>
      </div>
    </footer>
  )
}

export default Footer
