import React from "react"
import PropTypes from "prop-types"
import Header from "./header"
import Footer from "./footer"
import "../styles/styles.scss"
import "fontsource-cabin/400.css"
import "fontsource-cabin/600.css"

const Layout = ({ children, coloredHeader }) => (
  <div className="layout">
    <Header colored={coloredHeader} />
    <div className="content">
      <main>{children}</main>
    </div>
    <Footer />
  </div>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
