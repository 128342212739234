import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"

const Header = ({ colored }) => {
  const data = useStaticQuery(graphql`
    query {
      whiteLogo: file(relativePath: { eq: "Focal Logo_Full White.png" }) {
        childImageSharp {
          fixed(width: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      coloredLogo: file(relativePath: { eq: "Focal Logo_Full Colored.png" }) {
        childImageSharp {
          fixed(width: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  return (
    <header className="container">
      <Link to="/">
        <Img
          fixed={
            colored
              ? data.coloredLogo.childImageSharp.fixed
              : data.whiteLogo.childImageSharp.fixed
          }
          className="logo"
          fadeIn={false}
        />
      </Link>
    </header>
  )
}

export default Header
